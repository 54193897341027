<template>
  <head-panel>
    <template v-slot:body>Налаштування
      <router-link to="/secret">
        <button class="btn btn-secondary btn-sm btn-create-top ms-3" role="button">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
               class="bi bi-arrow-left-short" viewBox="0 0 16 16">
            <path fill-rule="evenodd"
                  d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"/>
          </svg>
          Повернутись
        </button>
      </router-link>
    </template>
  </head-panel>

  <div class="wrapper">
    <Alert ref="alert" class="ms-3 me-3"/>

    <div class="alert alert-danger" v-if="errors.length">
      <div v-for="error of errors" :key="error">{{ error.message }}</div>
    </div>
    <form @submit.prevent="submit">


      <div class="row ms-2 mb-4">
        <div class="col-xl-3">Інформ URL</div>
        <div class="col-lg-8">
          <input type="text"
                 class="form-control"
                 :class="{ 'is-invalid' : v$.form.informUrl.$error}"
                 v-model="form.informUrl">
          <div class="invalid-feedback" v-for="error of v$.form.informUrl.$errors" :key="error.$uid">
            {{ error.$message }}
          </div>
        </div>
      </div>

      <div class="row ms-2 mb-4">
        <div class="col-xl-3"></div>
        <div class="col-lg-8">
          <button type="submit" class="btn btn-primary">Save</button>
        </div>
      </div>


    </form>

  </div>
</template>

<script>

import Http from "../../lib/Http";
import {maxLength} from '@vuelidate/validators'
import {useVuelidate} from '@vuelidate/core'
import HeadPanel from "../../components/HeadPanel";
import Alert from "@/components/Alert.vue";

export default {
  name: "SecretForm",
  components: {HeadPanel, Alert},
  created() {
    this.fetch();
  },
  setup: () => ({v$: useVuelidate()}),
  validations() {
    return {
      form: {
        informUrl: {maxLength: maxLength(255)},
      }
    }
  },
  data() {
    return {
      errors: [],
      form: {
        informUrl: null,
      },
    }
  },
  methods: {
    fetch: function () {

      Http.get('/setting')
          .then((res) => {
            res.content.forEach(setting => {
              if (setting.name === 'inform:callback')
                this.form.informUrl = setting.value;
            })
          });
    },
    submit() {
      const form = this.v$.form;

      form.$touch();

      if (form.$error) return

      const data = {
        informUrl: this.form.informUrl?this.form.informUrl:null
      };

      Http.put('/setting', data)
          .then(() =>  this.$refs.alert.success('Успішно збережено'))
          .catch((e) => this.$refs.alert.danger(e.content.detail));
    }
  }
}
</script>

<style scoped>

</style>