<template>
  <head-panel>
    <template v-slot:body>Ключ
      <router-link to="/secret">
        <button class="btn btn-secondary btn-sm btn-create-top ms-3" role="button">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-left-short" viewBox="0 0 16 16">
            <path fill-rule="evenodd" d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"/>
          </svg>
          Повернутись
        </button>
      </router-link>
    </template>
  </head-panel>

  <div class="wrapper">


    <div class="alert alert-danger" v-if="errors.length">
      <div v-for="error of errors" :key="error">{{ error.message }}</div>
    </div>
    <form @submit.prevent="submit">


      <div class="row ms-2 mb-4">
        <div class="col-xl-3">Опис</div>
        <div class="col-lg-8">
          <input type="text"
                 class="form-control"
                 :class="{ 'is-invalid' : v$.form.description.$error}"
                 v-model="form.description">
          <div class="invalid-feedback" v-for="error of v$.form.description.$errors" :key="error.$uid">
            {{ error.$message }}
          </div>
        </div>
      </div>

      <div class="row ms-2 mb-4">
        <div class="col-xl-3"></div>
        <div class="col-lg-8">
          <button type="submit" class="btn btn-primary">Save</button>
        </div>
      </div>


    </form>

  </div>
</template>

<script>

import Http from "../../lib/Http";
import {required,  maxLength} from '@vuelidate/validators'
import {useVuelidate} from '@vuelidate/core'
import HeadPanel from "../../components/HeadPanel";

export default {
  name: "SecretForm",
  components: {HeadPanel},
  created() {
    this.fetch();
  },
  setup: () => ({v$: useVuelidate()}),
  data() {
    return {
      errors: [],
      form: {
        description: null,
      },
    }
  },
  validations() {
    return {
      form: {
        description: {required,  maxLength: maxLength(255)},
      }
    }
  },
  methods: {
    fetch: function () {
      if (this.$route.params.id === undefined)
        return;

      Http.get('/secret/' + this.$route.params.id)
          .then((res) => {
            this.form = res.content;
          });
    },
    submit() {
      const form = this.v$.form;

      form.$touch();

      if (form.$error) return

      const data = {
        description: this.form.description
      };

      let request = this.$route.params.id === undefined ?
          Http.post('/secret', data) :
          Http.put('/secret/' + this.$route.params.id, data);

      request.then(() => {
        this.$router.push('/secret')
      }).catch((e) => {
        this.errors = e.errors;
      });
    }
  }
}
</script>

<style scoped>

</style>