<template>
  <head-panel>
    <template v-slot:body>Транзакція</template>
  </head-panel>

  <div class="wrapper ps-3 pe-3" v-if="transaction">
    <Alert ref="alert"/>
    <div class="card ">
      <div class="card-body">
        <h5 class="card-title">Загальна інформація </h5>
        <h6 class="card-subtitle mb-2 text-muted"> {{ transaction.uuid }}</h6>
        <p class="card-text">
          {{ $filters.price(transaction.amount / 100) }} {{ transaction.balance.currency }}<br/>
          {{ transaction.status }}<br/>
          {{ transaction.description }}<br/>
          {{ transaction.createdAt }}<br/>
        </p>
      </div>
    </div>


    <div class="card mt-3">
      <div class="card-body">
        <h5 class="card-title">Баланс</h5>
        <h6 class="card-subtitle mb-2 text-muted">{{ transaction.balance.uuid  }}</h6>
        <p class="card-text">
          {{ transaction.balance.currency }}<br/>
          {{ transaction.balance.description }}<br/>
        </p>
        <router-link class="card-link" :to="'/balance/'+transaction.balance.uuid">Детальніше</router-link>
      </div>
    </div>


    <div class="card mt-3" v-if="this.invoice">
      <div class="card-body">
        <h5 class="card-title">Інвойс</h5>
        <h6 class="card-subtitle mb-2 text-muted">{{ this.invoice.uuid }}    <router-link :to="'/invoice/'+this.invoice.uuid">Деталі</router-link></h6>
        <p class="card-text">
          Загальна сума: {{ $filters.price(this.invoice.amount / 100) }} {{  this.transaction.balance.currency }}<br/>
          Зарахована сума: {{ $filters.price(this.invoice.accrualAmount / 100) }} {{  this.transaction.balance.currency }}<br/>
          Статус: {{ this.invoice.status }}<br/>
          Опис: {{ this.invoice.description }}<br/>
          Створений: {{ this.invoice.createdAt }}<br/>
          Термін дії: {{ this.invoice.expiredAt}}<br/>
        </p>
      </div>
    </div>


    <div class="card mt-3">
      <div class="card-body">
        <h5 class="card-title">Результат процесингу</h5>
        <p class="card-text">
          {{ transaction.processing}}
        </p>
      </div>
    </div>

  </div>
  <confirm-dialogue ref="confirmDialogue"/>
</template>

<script>
import Http from "../../lib/Http";
import HeadPanel from "../../components/HeadPanel";
import ConfirmDialogue from '../../components/ConfirmDialog.vue'
import Alert from "../../components/Alert";

export default {
  name: "View",
  components: {
    HeadPanel, ConfirmDialogue, Alert
  },
  created() {
    this.fetch();
  },
  data() {
    return {
      transaction: null,
      invoice: null
    }
  },
  methods: {
    fetch: function () {
      Http
          .get(this.$route.fullPath)
          .then((res) => {
            this.transaction = res.content;

            if(this.transaction.invoice)
              this.fetchInvoice(this.transaction.invoice.uuid);

          });
    },
    fetchInvoice: function (uuid) {
      Http
          .get("/invoice/"+uuid)
          .then((res) => {
            this.invoice = res.content;
          });
    },
  }
};
</script>

<style scoped>

</style>