import Sidebar from '../components/Sidebar.vue'
import List from "../views/balance/Index.vue";
import Edit from "../views/balance/View.vue";

export default [
    {
        path: "/balance",
        name: "BalanceList",
        components: {
            default: List,
            LeftSidebar: Sidebar
        }
    },
    {
        path: "/balance/:id",
        name: "BalanceEdit",
        components: {
            default: Edit,
            LeftSidebar: Sidebar
        }
    }
];