import Sidebar from '../components/Sidebar.vue'
import Edit from "../views/setting/Form.vue";

export default [
    {
        path: "/setting",
        name: "SettingCreate",
        components: {
            default: Edit,
            LeftSidebar: Sidebar
        }
    }
];