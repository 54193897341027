import Sidebar from '../components/Sidebar.vue'
import InvoiceList from "../views/invoice/Index.vue";
import InvoiceView from "../views/invoice/View.vue";


export default [
    {
        path: "/invoice",
        name: "InvoiceList",
        components: {
            default: InvoiceList,
            LeftSidebar: Sidebar
        }
    },
    {
        path: "/invoice/:id",
        name: "InvoiceView",
        components: {
            default: InvoiceView,
            LeftSidebar: Sidebar
        }
    }
];