<template>
  <head-panel>
    <template v-slot:body>Інвойси</template>
  </head-panel>

  <div class="wrapper">

    <div class="filter">
      <div class="item">
        <DropdownFilter
            label="Статус"
            name="status"
            path="invoice"
            :items="this.statuses"
        />
      </div>
      <div class="item search">
        <SearchFilter
            label="Пошук"
            :icon="true"
            name="search"
            path="invoice"
        />
      </div>

      <div class="item">
        <DateRangeFilter  v-model="filter.dateRange" :updateRoute="true" path="/invoice"/>
      </div>
    </div>

    <table class="table">
      <thead>
      <tr>
        <th scope="col">UUID</th>
        <th scope="col">Статус</th>
        <th scope="col">Сума</th>
        <th scope="col">Зовнішній ID</th>
        <th scope="col">Опис</th>
        <th scope="col">Створений</th>
        <th scope="col"></th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="item in items" :key="item">
        <td>{{ item.uuid }}</td>
        <td>{{ item.status }}</td>
        <td>{{ $filters.price(item.amount / 100) }} {{ item.balance.currency }}</td>
        <td>{{ item.externalId}}</td>
        <td>{{ item.description }}</td>
        <td>{{ $filters.dateTime(item.createdAt) }}</td>
        <td class="actions">
          <router-link :to="'/invoice/'+item.uuid">Деталі</router-link>
        </td>
      </tr>
      </tbody>
    </table>

    <Pagination
        :current-page="pagination.page"
        :total-pages="pagination.total"/>

  </div>
</template>

<script>
import Http from "../../lib/Http";
import HeadPanel from "../../components/HeadPanel";
import Pagination from "../../components/Pagination";
import SearchFilter from "../../components/SearchFilter";
import DateRangeFilter from "../../components/DateRangeFilter";
import DropdownFilter from "../../components/DropdownFilter";

export default {
  name: "Index",
  components: {
    HeadPanel, Pagination, SearchFilter, DateRangeFilter, DropdownFilter
  },
  created() {
    this.fetch();
  },
  computed: {
    statuses: function () {
      return [
        {'value': 'NEW', 'label': 'NEW'},
        {'value': 'CONFIRMED', 'label': 'CONFIRMED'}
      ];
    }
  },
  watch: {
    $route(n, o) {
      if (n.path === o.path)
        this.fetch();
    }
  },
  data() {
    return {
      filter:{
        dateRange: []
      },
      items: [],
      pagination: {
        page: 0,
        total: 0
      },
    }
  },
  methods: {
    fetch: function () {
        Http
            .get(this.$route.fullPath)
            .then((res) => {
              this.items = res.content;
              this.pagination.page = res.origin.headers.get("X-Current-Page") * 1;
              this.pagination.total = res.origin.headers.get("X-Page-Count") * 1;
            });
    }
  }
};
</script>

<style scoped>

.search{
  width: 340px;
}

</style>