<template>
  <head-panel>
    <template v-slot:body>Ключі
      <router-link to="/secret/create">
        <button class="btn btn-primary btn-sm btn-create-top ms-3 " role="button">
          Створити новий ключ
        </button>
      </router-link>

    </template>
  </head-panel>

  <div class="wrapper">

    <table class="table">
      <thead>
      <th scope="col">UUID</th>
      <th scope="col">Ключ</th>
      <th scope="col">Опис</th>
      <th scope="col">Створений</th>
      <th scope="col"></th>
      </thead>
      <tbody>
      <tr v-for="(secret, index) of secrets" :key="index">
        <td>{{ secret.uuid }}</td>
        <td>

          <span v-if="secret.id === showSecretId">{{ secret.secret }}</span>
          <span v-else>
              <svg @click="showSecretId = secret.id" xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                   fill="currentColor" class="bi bi-eye" viewBox="0 0 16 16">
            <path
                d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z"/>
            <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z"/>
          </svg>
          </span>


        </td>
        <td>{{ secret.description }}</td>
        <td>{{ secret.createdAt }}</td>
        <td class="actions">
          <router-link :to="{ path: '/secret/'+secret.uuid}" class=" ms-3">
            Редагувати
          </router-link>
          <a @click="deleteConfirm(secret.uuid)">Видалити</a>
        </td>
      </tr>
      <tr v-if="!secrets.length">
        <td colspan="6">Відсутні</td>
      </tr>
      </tbody>
    </table>

  </div>
  <confirm-dialogue ref="confirmDialogue"/>
</template>

<script>

import Http from "../../lib/Http";
import HeadPanel from "../../components/HeadPanel";
import ConfirmDialogue from '../../components/ConfirmDialog.vue'

export default {
  name: "AccessSecret",
  components: {HeadPanel, ConfirmDialogue},
  created() {
    this.fetch();
  },
  data() {
    return {
      showSecretId: null,
      secrets: [],
    }
  },
  methods: {
    fetch: function () {

      let url = '/secret';

      Http.get(url)
          .then((res) => {
            this.secrets = res.content;
          });
    },
    deleteConfirm: function (id) {

      this.$refs.confirmDialogue.show({
        title: 'Confirmation',
        message: 'Are you sure you want to delete secret?',
        okButton: 'Yes',
        cancelButton: 'no',
      }).then((ok) => {
        if (ok)
          Http
              .del('/secret/' + id)
              .then(() => this.fetch())
      });
    }
  }
}
</script>

<style scoped>
.bi-eye {
  cursor: pointer;
}
</style>