import {createApp} from 'vue'
import App from './App.vue'
import Router from './router/Index'
import VueCookies from 'vue-cookies'
import configFormatter from './lib/Formatter'
import configCharts from './config/Charts'
import configDate from './config/Date'
import Http from "./lib/Http";
import OAuth from './lib/OAuth2';


const oauth = new OAuth(process.env.VUE_APP_OAUTH_URL, process.env.VUE_APP_OAUTH_CLIENT_ID, process.env.VUE_APP_OAUTH_REDIRECT_URL);

if (location.pathname === "/" && Http.getQueryParameter('code')) {
    oauth.requestToken();
    location.href = "/";
} else {
    const app = createApp(App);
    app.use(VueCookies, {expire: '1d'});
    app.use(Router).mount('#app');
    configFormatter(app);
    configCharts(app);
    configDate(app);
}