<template>
  <head-panel>
    <template v-slot:body>
      Менеджери
      <a @click="managerAddModalShow=true" class="btn btn-primary btn-sm btn-create ms-2">Додати менеджера</a>
    </template>
  </head-panel>

  <div class="wrapper">

    <table class="table">
      <thead>
      <tr>
        <th scope="col">Email</th>
        <th scope="col">Статус</th>
        <th scope="col"></th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="item in items" :key="item">
        <td>{{ item.email }}</td>
        <td>{{ item.status }}</td>
        <td class="actions">
          <router-link :to="'/manager/'+item.uuid+'/permission'" >Дозволи</router-link>
          <a @click="remove(item)">Видалити</a>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
  <manager-add-modal @close="closeManagerAddModal" v-if="managerAddModalShow"/>
  <confirm-dialogue ref="confirmDialogue"/>
</template>

<script>
import Http from "../../lib/Http";
import HeadPanel from "../../components/HeadPanel";
import ManagerAddModal from "./ManagerAddModal";
import ConfirmDialogue from "@/components/ConfirmDialog.vue";

export default {
  name: "Index",
  components: {
    HeadPanel,  ConfirmDialogue, ManagerAddModal
  },
  created() {
    this.fetch();
  },
  watch: {
    $route(n, o) {
      if (n.path === o.path)
        this.fetch();
    }
  },
  data() {
    return {
      managerAddModalShow: false,
      items: [],
    }
  },
  methods: {
    fetch: function () {
      Http
          .get("/manager")
          .then((res) => {
            this.items = res.content;
          });
    },
    remove: function (item) {
      this.$refs.confirmDialogue.show({
        title: 'Видалення',
        message: 'Ви дійсно хочете видалити менеджера?',
        okButton: 'Так',
        cancelButton: 'Ні',
      }).then((ok) => {
        if (ok)
          Http
              .del( "/manager/" + item.uuid)
              .then(() => this.fetch());
      })
    },
    closeManagerAddModal: function () {
      this.managerAddModalShow = false;
      this.fetch();
    }
  }
};
</script>

<style scoped>


</style>