<template>
  <router-view name="LeftSidebar" v-if="isSidebar"/>
  <section class="home" v-bind:class="$route.name">
    <router-view/>
  </section>
</template>

<script>

import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"

export default {
  name: 'App',
  computed: {
    isSidebar() {
      return true
    }
  }
}
</script>

<style>
@import './assets/css/theme.css';
@import './assets/css/activity.css';
@import './assets/css/ql-editor.css';
</style>
