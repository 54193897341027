import Sidebar from '../components/Sidebar.vue'
import TransactionList from "../views/transaction/Index.vue";
import TransactionView from "../views/transaction/View.vue";

export default [
    {
        path: "/transaction",
        name: "TransactionList",
        components: {
            default: TransactionList,
            LeftSidebar: Sidebar
        }
    },
    {
        path: "/transaction/:id",
        name: "TransactionView",
        components: {
            default: TransactionView,
            LeftSidebar: Sidebar
        }
    }
];