<template>

  <modal class="short-modal" @close="close">

    <template v-slot:header>
      Додання нового менеджера
    </template>
    <template v-slot:body>

      <form @submit.prevent="submit">

        <div class="row ms-1 me-1 mb-3">
          <div class="col-xl-12 mb-1">Пошук</div>
          <div class="col-lg-12">

            <div class="input-group mb-3">
              <input type="text" class="form-control" placeholder="Введіть email менеджера" v-model="search"
                     :class="{ 'is-invalid' : error}" @click="error=false" @change="find">
              <span class="input-group-text" id="basic-addon2">
                 <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-search" viewBox="0 0 16 16">
                  <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>
                </svg>
              </span>
              <div class="invalid-feedback" v-if="error">
                Користувач за данними параметрами не знайдений. Перевірте коректність даних. Якщо користувач ще не
                зареєстрований в системі для початку йому потрібно пройти реєстрацію за посиланням
                <a href="https://marketplace.crelayer.com"></a>
              </div>
            </div>

            <div v-if="account" class="mt-3 bg-light p-3">
              <div class="mb-1"><strong>Знайдений користувач</strong></div>
              <div class="mb-1">Імя: {{ account.name ?? 'не вказано' }}</div>
              <div>Email: {{ account.email }}</div>
            </div>
            <div v-if="account" class="mt-4">Якщо дані користувача вірні написніть кнопку <strong>"додати"</strong>
            </div>
          </div>
        </div>

        <div class="row ms-1 mb-4">
          <div class="col-xl-6">
            <button type="submit" class="btn  btn-primary" :disabled="!account">Додати</button>
          </div>
        </div>

      </form>

    </template>
  </modal>


</template>

<script>

import Http from "../../lib/Http";
import Modal from "../../components/Modal.vue";

export default {
  name: "ManagerAddModal",
  components: {
    Modal
  },
  data() {
    return {
      error: false,
      search: null,
      account: null
    }
  },
  methods: {
    find: function () {
      Http.get( "/manager/find?search=" + this.search)
          .then(res => {
            this.account = res.content;
            this.error = !this.account;
          });
    },
    submit: function () {
      Http.post( "/manager/" + this.account.uuid + "/add")
          .then(() => {
            this.close();
          })
          .catch(() => {
            this.close();
          });
    },
    close: function () {
      this.$emit('close');
    }
  }
}
</script>

<style scoped>

</style>