<template>
  <head-panel>
    <template v-slot:body>Інвойс</template>
  </head-panel>

  <div class="wrapper ps-3 pe-3" v-if="invoice">
    <Alert ref="alert"/>


    <div class="card mt-3">
      <div class="card-body">
        <h5 class="card-title">Інвойс</h5>
        <h6 class="card-subtitle mb-2 text-muted">{{ this.invoice.uuid }}</h6>
        <p class="card-text">
          Загальна сума: {{ $filters.price(this.invoice.amount / 100) }} {{ this.invoice.balance.currency }}<br/>
          Зарахована сума: {{ $filters.price(this.invoice.accrualAmount / 100) }}<br/>
          <strong>Залишок: {{ $filters.price((this.invoice.amount - this.invoice.accrualAmount) / 100) }}  {{ this.invoice.balance.currency }}</strong><br/><br/>
          Статус: {{ this.invoice.status }}<br/>
          Опис: {{ this.invoice.description }}<br/>
          Створений: {{ this.invoice.createdAt }}<br/>
          Термін дії: {{ this.invoice.expiredAt }}<br/>
          Зовнішній ID: {{ this.invoice.externalId }}<br/>
        </p>
      </div>
      <ul class="list-group list-group-flush transaction">
        <li class="list-group-item actions" v-for="transaction of invoice.transactions" :key="transaction">

          <div class="row">
            <div class="col-2">{{ transaction.uuid }}</div>
            <div class="col-1">{{ $filters.price(transaction.amount / 100) }} {{ this.invoice.balance.currency }}</div>
            <div class="col-1">{{ transaction.type }}</div>
            <div class="col-1">{{ transaction.status }}</div>
            <div class="col-3">{{ transaction.description }}</div>
            <div class="col-2">{{ transaction.createdAt }}</div>
            <div class="col-2"><router-link :to="'/transaction/'+transaction.uuid">Деталі</router-link></div>
          </div>

        </li>
      </ul>
    </div>

    <form @submit.prevent="submitManual" ref="transfer">
      <div class="card mt-4">
        <div class="card-body">
          <h5 class="card-title">Ручне зарахування</h5>
          <div class="row">
            <div class="col-3">
              <input type="text" class="form-control" v-model="form.amount" placeholder="Сума"
                     :class="{ 'is-invalid' : v$.form.amount.$error}">
              <div class="invalid-feedback" v-for="error of v$.form.amount.$errors" :key="error.$uid">
                {{ error.$message }}
              </div>
            </div>
            <div class="col-4">
              <input type="text" class="form-control" v-model="form.description" placeholder="Коментар до переказу"
                     :class="{ 'is-invalid' : v$.form.description.$error}">
              <div class="invalid-feedback" v-for="error of v$.form.description.$errors" :key="error.$uid">
                {{ error.$message }}
              </div>
            </div>
            <div class="col-1">
              <button type="submit" class="btn btn-primary">Зарахувати</button>
            </div>
          </div>
        </div>
      </div>
    </form>

    <div class="card mt-3">
      <div class="card-body">
        <h5 class="card-title">Баланс</h5>
        <h6 class="card-subtitle mb-2 text-muted">{{ invoice.balance.uuid }}</h6>
        <p class="card-text">
          {{ invoice.balance.currency }}<br/>
          {{ invoice.balance.description }}<br/>
        </p>
        <router-link class="card-link" :to="'/balance/'+invoice.balance.uuid">Детальніше</router-link>
      </div>
    </div>

  </div>
  <confirm-dialogue ref="confirmDialogue"/>
</template>

<script>
import HeadPanel from "../../components/HeadPanel";
import ConfirmDialogue from '../../components/ConfirmDialog.vue'
import Alert from "../../components/Alert";
import Http from "@/lib/Http";
import {useVuelidate} from "@vuelidate/core";
import {maxLength, required} from "@vuelidate/validators";

export default {
  name: "View",
  components: {
    HeadPanel, ConfirmDialogue, Alert
  },
  created() {
    this.fetch();
  },
  setup: () => ({v$: useVuelidate()}),
  validations() {
    return {
      form: {
        amount: {
          required
        },
        description: {
          required,
          maxLength: maxLength(255)
        }
      },
    };
  },
  data() {
    return {
      invoice: null,
      form: {
        amount: null,
        description: null
      },
    }
  },
  methods: {
    fetch: function () {
      Http
          .get("/invoice/" + this.$route.params.id)
          .then((res) => {
            this.invoice = res.content;
          });
    },

    submitManual: async function () {

      const result = await this.v$.form.$validate()

      if (!result) return

      this.$refs.confirmDialogue.show({
        title: 'Заразування коштів',
        message: "Ви дійсно хочете зарахувати: <br/><strong>"
            + this.form.amount + " "
            + this.invoice.balance.currency
            + "</strong> <br/> на рахунок інвойсу ?",
        okButton: 'Так',
        cancelButton: 'Ні',
      }).then((ok) => {
        if (ok)
          Http
              .post("/payment/invoice/" + this.invoice.uuid + "/manual", {
                amount: this.form.amount * 100,
                description: this.form.description
              })
              .then(() => {
                this.fetch();
                this.form = {
                  amount: null,
                  description: null
                };
                this.v$.$reset()
                this.$refs.alert.success('Success transferred')
              })
              .catch(e => this.$refs.alert.danger(e.content.detail));
      })
    },
  }
};
</script>

<style scoped>
</style>