<template>

  <div class="row mt-4">
    <div class="col">
      <div class="btn-group btn-group-sm" role="group" aria-label="Basic radio toggle button group">
        <input type="radio" class="btn-check" v-model="period" value="DAY" name="btnradio" id="btnradio1"
               autocomplete="off" checked>
        <label class="btn btn-outline-primary" for="btnradio1">День</label>

        <input type="radio" class="btn-check" v-model="period" value="WEEK" name="btnradio" id="btnradio2"
               autocomplete="off">
        <label class="btn btn-outline-primary" for="btnradio2">Тиждень</label>

        <input type="radio" class="btn-check" v-model="period" value="MONTH" name="btnradio" id="btnradio3"
               autocomplete="off">
        <label class="btn btn-outline-primary" for="btnradio3">Місяць</label>
      </div>
    </div>
    <div class="col-auto">
      <DateRangeFilter @onChange="changeDateRange" v-model="dateRange" :updateRoute="true"
                       :path="'/balance/'+balance.uuid"/>
    </div>
  </div>

  <div style="height: 300px;">
    <v-chart class="chart" :option="ordersChartData"/>
  </div>

</template>

<script>
import Http from "../../lib/Http";
import DateRangeFilter from "../../components/DateRangeFilter";
import moment from 'moment'

export default {
  name: "Index",
  props: {
    balance: Object
  },
  components: {
    DateRangeFilter
  },
  created() {
    this.fetchStat();
  },
  watch: {
    period() {
      this.fetchStat();
    },
  },
  data() {
    return {
      dateRange: this._defaultDatePeriod(),
      period: 'DAY',
      orderSummary: {
        dayOrdersCount: 0,
        newOrderCount: 0,
      },
      callbackSummary: {
        newCallbackCount: 0
      },
      ordersChartData: null
    }
  },
  methods: {
    changeDateRange: function (range) {
      this.dateRange = range;
      this.fetchStat();
    },
    fetchStat: function () {
      const from = this.dateRange[0];
      const to = this.dateRange[1];
      Http.get('/analytics/transaction?&period=' + this.period + '&balance=' + this.balance.uuid + '&from=' + from + '&to=' + to)
          .then(res => {
            this.ordersChartData = this._ordersChartData(res.content);
          });
    },
    _defaultDatePeriod: function () {

      if (this.$route.query.from && this.$route.query.to)
        return [this.$route.query.from, this.$route.query.to]

      return [moment().subtract(1, 'month').format("YYYY-MM-DD"), moment().add(1, 'day').format("YYYY-MM-DD")];
    },
    _ordersChartData: function (data) {

      const dates = new Map();
      const xAxisData = [];
      const debitLineData = [];
      const creditLineData = [];

      for (let item of data) {
        if (!dates.has(item.date))
          dates.set(item.date, [0, 0]);

        const v = dates.get(item.date);

        if (item.amount > 0)
          v[1] = item.amount / 100 ;
        else
          v[0] = item.amount / 100 * -1;

        dates.set(item.date, v);
      }


      dates.forEach((value, key) => {
        xAxisData.push(key);
        creditLineData.push(value[0]);
        debitLineData.push(value[1]);
      })



      return {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        legend: {
          // Try 'horizontal'
          // icon: 'rect',
          // orient: 'horizontal',
          // right: 10,
          // top: 'center'
        },
        grid: {
          left: '0%',
          right: '0%',
          bottom: '0%',
          // width: '100%',
          containLabel: true
        },
        xAxis: [
          {
            // interval: 100,
            // min: 5,
            // max: 20,
            type: 'category',
            boundaryGap: false,
            data: xAxisData
          }
        ],
        yAxis: [
          {
            type: 'value',
            // splitLine: {
            //   show: false
            // }
          },
        ],
        series: [
          {
            name: 'Дохід ' + this.balance.currency,
            type: 'line',
            // stack: 'Total',
            // symbolSize: 8,
            lineStyle: {
              color: '#03d00d'
            },
            itemStyle: {
              color: '#03d00d'
            },
            areaStyle: {
              opacity: 0.1,
              color: '#03d00d'
            },
            data: debitLineData
          },
          {
            name: 'Витрати ' + this.balance.currency,
            type: 'line',
            // stack: 'Total',
            // symbolSize: 8,
            lineStyle: {
              color: '#fa1515',
            },
            itemStyle: {
              color: '#fa1515',
            },
            areaStyle: {
              opacity: 0.1,
              color: '#fa1515',
            },
            data: creditLineData
          }
        ]
      };
    }
  }
};
</script>

<style scoped>

.today {
  background: rgba(47, 126, 216, .05);
  border: 1px solid rgba(47, 126, 216, .4);
  border-radius: 5px;
}

.today strong {
  line-height: 1.3;
  font-size: 18px;
}

.today strong span {
  font-size: 11px;
  color: #cccccc;
  font-weight: normal;
  margin-left: 4px;
  margin-top: -2px;
  position: absolute;
}

.today label {
  display: block;
  font-size: 12px;
}

</style>