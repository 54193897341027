<template>
  <head-panel>
    <template v-slot:body>Баланс</template>
  </head-panel>

  <div class="wrapper ps-3 pe-3" v-if="balance">
    <Alert ref="alert"/>
    <div class="card ">
      <div class="card-body">
        <h5 class="card-title">Загальна інформація</h5>
        <h6 class="card-subtitle mb-2 text-muted">{{ balance.uuid }}</h6>
        <p class="card-text">
          {{ $filters.price(balance.amount / 100) }} {{ balance.currency }}<br/>
          {{ balance.description }}
        </p>
        <router-link class="card-link" :to="'/transaction?search='+balance.uuid">Транзакції</router-link>
      </div>
    </div>


    <form @submit.prevent="submitTransfer" ref="transfer">
      <div class="card mt-4">
        <div class="card-body">
          <h5 class="card-title">Переказ</h5>
          <div class="row">
            <div class="col-3">
              <input type="text" class="form-control" v-model="payForm.amount" placeholder="Сума"
                     :class="{ 'is-invalid' : v$.payForm.amount.$error}">
              <div class="invalid-feedback" v-for="error of v$.payForm.amount.$errors" :key="error.$uid">
                {{ error.$message }}
              </div>
            </div>
            <div class="col-4">
              <input type="text" class="form-control" v-model="payForm.balance" placeholder="Баланс uuid"
                     :class="{ 'is-invalid' : v$.payForm.balance.$error}">
              <div class="invalid-feedback" v-for="error of v$.payForm.balance.$errors" :key="error.$uid">
                {{ error.$message }}
              </div>
            </div>
            <div class="col-4">
              <input type="text" class="form-control" v-model="payForm.description" placeholder="Коментар до переказу"
                     :class="{ 'is-invalid' : v$.payForm.description.$error}">
              <div class="invalid-feedback" v-for="error of v$.payForm.description.$errors" :key="error.$uid">
                {{ error.$message }}
              </div>
            </div>
            <div class="col-1">
              <button type="submit" class="btn btn-primary">Переказати</button>
            </div>
          </div>
        </div>
      </div>
    </form>

    <form @submit.prevent="submitDescription">
      <div class="card  mt-4">
        <div class="card-body">
          <h5 class="card-title">Опис</h5>
          <div class="row">
            <div class="col-6">
              <input type="text" class="form-control" v-model="form.description" placeholder="Назва магазину"
                     :class="{ 'is-invalid' : v$.form.description.$error}">
              <div class="invalid-feedback" v-for="error of v$.form.description.$errors" :key="error.$uid">
                {{ error.$message }}
              </div>
            </div>
            <div class="col-1">
              <button type="submit" class="btn btn-primary">Зберегти</button>
            </div>
          </div>
        </div>
      </div>
    </form>

    <hr/>

    <chart :balance="balance"/>

  </div>
  <confirm-dialogue ref="confirmDialogue"/>
</template>

<script>
import Http from "../../lib/Http";
import HeadPanel from "../../components/HeadPanel";
import {useVuelidate} from "@vuelidate/core";
import {maxLength, minLength, required} from "@vuelidate/validators";
import ConfirmDialogue from '../../components/ConfirmDialog.vue'
import Alert from "../../components/Alert";
import Chart from "./Chart";

export default {
  name: "BalanceStat",
  components: {
    HeadPanel, ConfirmDialogue, Alert, Chart
  },
  created() {
    this.fetch();
  },
  setup: () => ({v$: useVuelidate()}),
  validations() {
    return {
      payForm: {
        amount: {
          required
        },
        balance: {
          required,
          maxLength: maxLength(36),
          minLength: minLength(36),
        },
        description: {
          required,
          maxLength: maxLength(255)
        }
      },
      form: {
        description: {
          required,
          maxLength: maxLength(255)
        },
      }
    };
  },
  data() {
    return {
      balance: null,
      payForm: {
        amount: null,
        balance: null,
        description: null
      },
      form: {
        description: null
      }
    }
  },
  methods: {
    fetch: function () {
      Http
          .get(this.$route.fullPath)
          .then((res) => {
            this.balance = res.content;
            this.form = {
              description: res.content.description
            };
          });
    },
    submitTransfer: async function () {

      const result = await this.v$.payForm.$validate()

      if (!result) return

      const detail = await Http.get("/balance/" + this.payForm.balance + "/description");

      this.$refs.confirmDialogue.show({
        title: 'Переказ коштів',
        message: "Ви дійсно хочете переказати: <br/><strong>"
            + this.payForm.amount + " "
            + this.balance.currency
            + "</strong> <br/> на рахунок:<br/> <strong>" + detail.content.uuid + "</strong>" +
            "<br/>" + detail.content.description + "<br/>  ?",
        okButton: 'Так',
        cancelButton: 'Ні',
      }).then((ok) => {
        if (ok)
          Http
              .post("/accrual/" + this.balance.uuid + "/transfer", {
                amount: this.payForm.amount * 100,
                balance: this.payForm.balance,
                description: this.payForm.description
              })
              .then(() => {
                this.fetch();
                this.payForm = {
                  amount: null,
                  balance: null,
                  description: null
                };
                this.v$.$reset()
                this.$refs.alert.success('Success transferred')
              })
              .catch(e => this.$refs.alert.danger(e.content.detail));
      })
    },

    submitDescription: async function () {

      const result = await this.v$.form.$validate()

      if (!result) return

      Http
          .post("/balance/" + this.balance.uuid, {
            description: this.form.description
          })
          .then(() => {
            this.fetch();
            this.$refs.alert.success('Success transferred');
          })
          .catch(e => this.$refs.alert.danger(e.content.detail));
    }
  }
};
</script>

<style scoped>

</style>