import {createRouter, createWebHashHistory} from "vue-router";
import Sidebar from '../components/Sidebar.vue'
import Index from "../views/main/Index"
import invoice from "./Invoice"
import transaction from "./Transaction"
import balance from "./Balance"
import manager from "./Manager"
import setting from "./Setting"
import secret from "./Secret"

const routes = [
    {
        path: "/",
        name: "Main",
        components: {
            default: Index,
            LeftSidebar: Sidebar
        }
    },
    ...manager,
    ...balance,
    ...setting,
    ...secret,
    ...invoice,
    ...transaction
];


const router = createRouter({
    history: createWebHashHistory(),
    routes
})

export default router