<template>
  <div class="search" v-bind:class="icon ? 'icon':''">
    <svg viewBox="0 0 24 24" fill="currentColor" class="iconLeftSearch-0-2-67 icon-0-2-33">
      <path
          d="M16.5 14H15.71L15.43 13.73C16.41 12.59 17 11.11 17 9.5C17 5.91 14.09 3 10.5 3C6.91 3 4 5.91 4 9.5C4 13.09 6.91 16 10.5 16C12.11 16 13.59 15.41 14.73 14.43L15 14.71V15.5L19.2543 19.7457C19.6662 20.1569 20.3334 20.1566 20.745 19.745C21.1566 19.3334 21.1569 18.6662 20.7457 18.2543L16.5 14ZM10.5 14C8.01 14 6 11.99 6 9.5C6 7.01 8.01 5 10.5 5C12.99 5 15 7.01 15 9.5C15 11.99 12.99 14 10.5 14Z"></path>
    </svg>
    <input type="text" class="form-control" v-bind:placeholder="label" v-model="search">
  </div>

</template>

<script>
import {debounce} from "../lib/Util";

export default {
  name: "Filter",
  props: {
    modelValue: String,
    label: String,
    icon: Boolean,
    name: String,
    path: String
  },
  computed: {},
  watch: {
    search(val) {
      this.$emit('update:modelValue', val.toLowerCase());
      debounce(() => this.routePush(val.toLowerCase()), 400)();
    }
  },
  created() {
    const q = this.$route.query;
    this.search = q[this.name] ? q[this.name] : null;
  },
  data() {
    return {
      search: null,
    }
  },
  methods: {
    routePush: function (val) {
      let query = {};
      for (let p of Object.keys(this.$route.query)) {
        if (p === 'page')
          continue;
        query[p] = this.$route.query[p];
      }

      if (val)
        query[this.name] = val;
      else
        delete query[this.name];

      this.$router.push({path: this.path, query: query});
    }
  }
}
</script>

<style scoped>

.search {
  position: relative;
}

.search input {
  padding: 0.275rem 0.75rem 0.275rem 10px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.11);
}

.search.icon input{
  padding-left: 40px;
}

::placeholder {
  color: #999;
}

.search svg {
  margin-right: 12px;
  width: 22px;
  height: 22px;
  display: none;
  flex-shrink: 0;
  user-select: none;
  color: #0000008a;
  float: left;
  position: absolute;
  left: 10px;
  top: 5px;
}

.search.icon svg {
  display: inline-block;
}

.search svg path {
  color: #999;
}

</style>