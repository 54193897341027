<template>
  <head-panel>
    <template v-slot:body> Дозволи</template>
  </head-panel>

  <div class="wrapper">
    <Alert ref="alert"/>


    <form @submit.prevent="submit">

      <template v-for="(group, index) in permissions" :key="group">
        <hr v-if="index"/>
        <div class="row ms-2 mb-4">
          <div class="col-xl-3">{{ group.name }}</div>
          <div class="col-lg-8">

            <div class="form-check mb-3" v-for="item in group.items" :key="item">
              <input class="form-check-input" type="checkbox" v-bind:id="item.value"
                     v-model="form.permission[item.value]" :name="item.value">
              <label class="form-check-label" v-bind:for="item.value">{{ item.label }}</label>
            </div>

          </div>
        </div>
      </template>


      <div class="row ms-2 mb-4">
        <div class="col-xl-6">
          <button type="submit" class="btn btn-primary">Зберегти</button>
        </div>
      </div>

    </form>
  </div>
</template>

<script>
import Http from "../../lib/Http";
import HeadPanel from "../../components/HeadPanel";
import Alert from "../../components/Alert";

export default {
  name: "Edit",
  components: {
    HeadPanel, Alert
  },
  created() {
    if (this.$route.params.id)
      this.fetch();
  },
  computed: {
    permissions: function () {

      return [
        {
          name: 'Баланси аккаунта',
          items: [
            {label: 'Перегляд балансів користувачів', value: 1 << 0},
            {label: 'Ручне зарахування коштів', value: 1 << 6},
            {label: ' Оплата з балансу', value: 1 << 7},
          ]
        },
      ];
    }
  },
  data() {
    return {
      form: {
        permission: {}
      }
    }
  },
  methods: {
    fetch: function () {
      const id = this.$route.params.id;
      Http
          .get( "/manager/" + id + "/permission")
          .then((res) => {
           this.form.permission = res.content.reduce(function (result, p) {
              result[p.mask] = true;
              return result
            }, {});
          });
    },
    submit: async function () {
      const permissions = [];
      Object.entries(this.form.permission).forEach(item => {
        const [k, v] = item;
        if (v)
          permissions.push(k);
      });

      Http
          .post( "/manager/" + this.$route.params.id + "/permission", permissions)
          .then(() => {
            this.$refs.alert.success('Успішно збережено');
          });
    },
  }
};
</script>

<style scoped>
.preview {
  float: left;
  margin-right: 10px;
  position: relative;
}

.preview svg {
  color: red;
  cursor: pointer;
  margin-left: 20px;
}
</style>