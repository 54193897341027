import Sidebar from '../components/Sidebar.vue'
import List from "../views/secret/Index.vue";
import Edit from "../views/secret/Form.vue";

export default [
    {
        path: "/secret",
        name: "SecretList",
        components: {
            default: List,
            LeftSidebar: Sidebar
        }
    },
    {
        path: "/secret/create",
        name: "SecretCreate",
        components: {
            default: Edit,
            LeftSidebar: Sidebar
        }
    },
    {
        path: "/secret/:id",
        name: "SecretEdit",
        components: {
            default: Edit,
            LeftSidebar: Sidebar
        }
    }
];